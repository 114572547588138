<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name:
                $route.params && $route.params.backLinkName
                  ? $route.params.backLinkName
                  : 'r_connectors-intranet'
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("one-click", "info-add", "info") }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" style="fill: #fff;" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm @submit="submitForm" v-slot="{ errors }" class="form edit-form">
      <ul class="clebex-item-section pill">
        <li class="clebex-item-section-item">
          <div class="clebex-item-content-wrapper">
            <dl class="clebex-item-dl justify-start">
              <dt class="clebex-item-dt">
                <div class="checkbox slide">
                  <input
                    id="isActive"
                    type="checkbox"
                    v-model="newIntranet.is_active"
                  />
                  <label for="isActive"></label>
                </div>
              </dt>
              <dd class="clebex-item-dd justify-start">
                {{ displayLabelName("connectors.intranet.is-active") }}
              </dd>
            </dl>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoName"
                >{{ displayLabelName("connectors.intranet.name") }}
              </label>
              <Field
                name="name"
                as="input"
                rules="required"
                type="text"
                id="infoName"
                v-model="newIntranet.name"
              />
              <span class="error-message" v-if="errors.name">
                {{ errors.name }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoUrl"
                >{{ displayLabelName("connectors.intranet.url") }}
              </label>
              <Field
                name="url"
                as="input"
                rules="required"
                type="text"
                id="infoUrl"
                v-model="newIntranet.url"
              />
              <span class="error-message" v-if="errors.number">
                {{ errors.number }}
              </span>
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label" for="infoDescription"
                >{{ displayLabelName("connectors.intranet.description") }}
              </label>
              <Field
                name="description"
                as="input"
                type="text"
                id="infoDescription"
                v-model="newIntranet.description"
              />
            </div>
          </div>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>

    <template v-slot:footer>
      <nav class="actions-menu theme-gray"></nav>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";

export default {
  name: "IntranetAdd",
  data() {
    return {
      newIntranet: {
        is_active: 0,
        name: "",
        url: "",
        description: ""
      }
    };
  },
  computed: {
    ...mapState("intranet", ["intranet"])
  },
  methods: {
    ...mapActions("intranet", ["createIntranet"]),
    submitForm(values) {
      values.is_active = Number(this.newIntranet.is_active);
      if (values) {
        this.createIntranet(values).then(() => {
          this.newIntranet = {
            is_active: 0,
            name: "",
            url: "",
            description: ""
          };
          this.$router.push({
            name: "r_connectors-intranet"
          });
        });
      }
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
